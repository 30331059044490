export const MAX_USERGROUP_RIGHTS = 69;


export enum UsergroupRight {
    UR_HOME = 0,
    UR_FILTER_ALL = 57, // 1) Alle Filter an/aus. 2) Wenn aus, sieht User nur die Projekte wo er auch Projektmember ist.

    UR_CONF_CONFIGURATION = 1,
    //
    UR_ADMIN_SIDEBAR = 2,
    //
    UR_ADMIN_USERGROUP = 3,
    UR_ADMIN_USERGROUP_INSERT = 40,
    UR_ADMIN_USERGROUP_DELETE = 41,
    //
    UR_ADMIN_USER = 4,
    UR_ADMIN_USER_INSERT = 42,
    UR_ADMIN_USER_DELETE = 43,
    //
    UR_ADMIN_LOGBOOK=69,
    //
    UR_BASE_SIDEBAR = 5,
    //
    UR_BASE_PRODUCT = 6,
    UR_BASE_PRODUCT_INSERT = 34,
    UR_BASE_PRODUCT_DELETE = 35,
    UR_BASE_PRODUCT_EDIT = 62,
    //
    UR_BASE_CUSTOMER = 7,
    UR_BASE_CUSTOMER_INSERT = 36,
    UR_BASE_CUSTOMER_DELETE = 37,
    //
    UR_BASE_SUPPLIER = 8,
    UR_BASE_SUPPLIER_INSERT = 38,
    UR_BASE_SUPPLIER_DELETE = 39,
    //
    UR_BASE_CATALOG = 46,
    UR_BASE_CATALOG_INSERT = 47,
    UR_BASE_CATALOG_DELETE = 48,
    //
    UR_PROJECT_SIDEBAR = 16,
    //
    UR_PROJECT_TEMPLATE = 9,
    UR_PROJECT_TEMPLATE_INSERT = 17,
    UR_PROJECT_TEMPLATE_DELETE = 18,
    //
    UR_PROJECT_PROJECT = 10,
    UR_PROJECT_PROJECT_INSERT = 19,
    UR_PROJECT_PROJECT_DELETE = 20,

    UR_PROJECT_ACTIVITY_INSERT = 44,
    UR_PROJECT_ACTIVITY_DELETE = 45,
    //
    UR_PPAP_SIDEBAR = 11,
    //
    UR_PPAP_TEMPLATE = 12,
    UR_PPAP_TEMPLATE_INSERT = 27,
    UR_PPAP_TEMPLATE_DELETE = 28,
    //
    UR_PPAP_PPAP = 13,
    UR_PPAP_PPAP_INSERT = 29,
    UR_PPAP_PPAP_DELETE = 30,
    //
    UR_AUDIT_SIDEBAR = 15,
    //
    UR_AUDIT_TEMPLATE = 21,
    UR_AUDIT_TEMPLATE_INSERT = 22,
    UR_AUDIT_TEMPLATE_DELETE = 23,
    //
    UR_AUDIT_AUDIT = 24,
    UR_AUDIT_AUDIT_INSERT = 25,
    UR_AUDIT_AUDIT_DELETE = 26,
    //
    UR_INSPECTION_SIDEBAR = 49,
    UR_INSPECTION_PLAN = 50,
    UR_INSPECTION_PLAN_INSERT = 51,
    UR_INSPECTION_PLAN_DELETE = 52,
    //
    UR_INSPECTION_INSPECTION = 53,
    UR_INSPECTION_INSPECTION_INSERT = 54,
    UR_INSPECTION_INSPECTION_DELETE = 55,
    //
    UR_TASK_SIDEBAR = 14,
    //
    UR_TASK_TASK = 31,
    UR_TASK_TASK_INSERT = 32,
    UR_TASK_TASK_DELETE = 33,
    //UR_TASK_TASK_FILTER_RESPONSIBLE = 56,

    UR_GEMBA_SIDEBAR = 61,
    UR_GEMBA_GEMBA = 58,
    UR_GEMBA_TEMPLATE = 63,
    UR_GEMBA_TEMPLATE_INSERT = 64,
    UR_GEMBA_TEMPLATE_DELETE = 65,
    UR_GEMBA_TEMPLATE_EDIT = 66,


    UR_ACTIVITY_SIDEBAR = 59,
    UR_ACTIVITY_ACTIVITIES = 60,

    UR_MOBILE_SIDEBAR = 67,
    UR_MOBILE_ACTIVITIES = 68

}

