import {BaseStore, IBaseStore} from "./base-store";
import {actionColumns, IAction} from "../models/Action";
import {RootStore} from "../routes/root-store";
import {action} from "mobx";
import {RouterState} from "mobx-state-router";
import {IDataset} from "../components/dataset/IDataset";
import {IProjectActivity, projectActivityColumns} from "../models/ProjectActivity";
import {Dataset} from "../components/dataset/dataset";
import * as R from 'ramda';


export interface IProjectActionStore extends IBaseStore<IAction> {
    dsProjectActivity: IDataset<IProjectActivity>
}

export class ProjectActionStore extends BaseStore<IAction> {

    dsProjectActivity: IDataset<IProjectActivity>

    constructor(rootStore: RootStore) {
        super(rootStore, '/gridApi/action/', actionColumns,);
        this.dsProjectActivity = new Dataset('/gridApi/projectactivity/', projectActivityColumns)
    }


    beforeEnter = async (fromState: RouterState, toState: RouterState) => {
        this.dsCheck.dataUrl = '/gridApi/project/'
        this.dsCheck.filter = {projectno: toState.params.projectno};
        await this.dsCheck.open();
        const rv = this.dsCheck.data.length === 1;
        this.dsCheck.close();
        return rv;
    }


    @action.bound
    async onEnter(fromState: RouterState, toState: RouterState) {
        this.dsProjectActivity.filter = toState.params;

        await this.dsProjectActivity.open();
        this.ds.filter = R.mergeRight({
            typ: this.dsProjectActivity.actual.activitytyp,
            key1: this.dsProjectActivity.actual.link
        }, toState.queryParams)
        this.dsProjectActivity.close();
        await this.ds.open();
    }

    @action.bound
    close() {
        this.ds.close();
    }


}