import React from "react";
import moment from "moment/moment";


export class DateTimeRenderer extends React.Component<any, any> {

    render() {
        const {value} = this.props;
        return (<span>{value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : ''}</span>)
    }
}


