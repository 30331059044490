import * as React from 'react';
import {inject, observer} from "mobx-react";
import {IBaseView} from "../../_base/base-view";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {HeaderUser} from "./header-user";
import {HeaderLogin} from "./header-login";


@inject('rootStore')
@observer
export class Header extends React.Component<IBaseView, any> {

    render() {
        const {
            rootStore: {
                appStore: {toggleSidebar, headerTitle},
                authStore: {loggedIn},
            },
        } = this.props;
        return (
            <div className="header">
                <div className="header-left">
                    <div className="toggle-button" onClick={toggleSidebar}>
                        <FontAwesomeIcon icon="bars"/>
                    </div>
                </div>
                <div className="center">
                    {headerTitle}
                </div>
                <div className="right">
                    <div className="inner">
                        {loggedIn ? <HeaderUser/> : <HeaderLogin/>}
                    </div>
                </div>
            </div>

        )
    }
}

