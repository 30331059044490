import React from 'react';
import {IBaseView} from "../base-view";
import {inject, observer} from "mobx-react";
import {Dialog} from "../../../components/dialog/Dialog";
import {DbForm} from "../../../components/dbform/db-form";
import {action, observable, runInAction} from "mobx";
import {IField} from "../../../components/dataset/IField";
import {IconButton} from "../styled/icon-button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {UncontrolledTooltip} from "reactstrap";


interface IBaseTableFilterMenu extends IBaseView {
    storeName: string;
    filtername: string;
}


@inject('rootStore')
@observer
export class BaseTableFilterMenu extends React.Component<IBaseTableFilterMenu, {}> {

    @observable
    isOpen: boolean = false;


    @action.bound
    openDialog() {
        const {storeName} = this.props;
        const {rootStore: {[storeName]: {cdsFilter}}} = this.props;
        this.isOpen = !this.isOpen;
        cdsFilter.edit();
    }

    @action.bound
    cancelDialog() {
        const {storeName} = this.props;
        const {rootStore: {[storeName]: {cdsFilter}}} = this.props;
        cdsFilter.cancel();
        this.isOpen = false;
    }

    @action.bound
    async okDialog() {
        const {filtername} = this.props;
        const {storeName} = this.props;
        const store = this.props.rootStore[storeName];
        const {rootStore: {[storeName]: {cdsFilter}}} = this.props;
        this.isOpen = false;
        let filter: any = {};
        Object.keys(cdsFilter.actual)
            .forEach((field) => {
                if (cdsFilter.actual[field]) {
                    filter[field] = cdsFilter.actual[field]
                }
            });
        await cdsFilter.post();
        await store.saveFilter(filtername);
        store.close();
        runInAction(async () => {
            await store.open(filter);
        });
    }

    @action.bound
    clearFilter() {
        const {storeName} = this.props;
        const {rootStore: {[storeName]: {cdsFilter}}} = this.props;
        cdsFilter.columns
            .forEach
            ((f: IField<any>) => {
                console.log(f.fieldName)
                switch (f.dataType) {
                    case 'string':
                        cdsFilter.actual[f.fieldName] = "";
                        break;
                    case "number":
                        cdsFilter.actual[f.fieldName] = undefined;
                        break;
                    case "boolean":
                        cdsFilter.actual[f.fieldName] = undefined
                }
            })

    }

    render() {
        const {storeName} = this.props;
        const {rootStore: {[storeName]: {cdsFilter}, langStore: {lang}}} = this.props;
        return (
            <>
                <IconButton
                    onClick={this.openDialog}
                    id={"btn-filter"}
                >
                    <FontAwesomeIcon icon="filter"/>
                </IconButton>
                <UncontrolledTooltip placement="bottom-end" target={"btn-filter"}>
                    {lang.FILTER}
                </UncontrolledTooltip>
                <Dialog
                    title={lang.FILTER}
                    isOpen={this.isOpen}
                    onCancel={this.cancelDialog}
                    onOk={this.okDialog}
                    extraButtons={<IconButton onClick={this.clearFilter}><FontAwesomeIcon
                        icon={["fad", "eraser"]}/></IconButton>}
                >
                    <DbForm ds={cdsFilter} colCount={1}/>

                </Dialog>
            </>
        )
    }

}