import styled from "styled-components";

/**
 *  AuditCollectLayout wird online und offline Audit gleichermassen  benutzt.
 *  Deshalb hier abgelegt.
 */
export const AuditCollectLayout = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  //
  display: flex;
  flex-direction: column;
  //
  color: ${props => props.theme.color};
  background-color: ${props => props.theme.bgBody};
  overflow-x: hidden;
  overflow-y: auto;
  //


  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex: 0 0 auto;
    color: ${props => props.theme.color};
    background-color: ${props => props.theme.bg};
    padding: 0.5rem 2rem;
    font-size: 1rem;
    font-weight: 500;

    .right {
      flex: 0 0 auto;
    }

    .center {
      flex: 1 1 auto;
    }

    .right {
      flex: 0 0 auto;
    }

  }

  .top-area {
    display: block;
    flex: 0 0 auto;
    padding: ${props => props.theme.gutter};
    box-shadow: ${props => props.theme.boxShadowInset};
  }

`;