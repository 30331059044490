import {BaseStore, IBaseStore} from "./base-store";
import {activityColumns, IActivity} from "../models/Activity";
import {IRootStore} from "../routes/root-store";
import {action} from "mobx";
import {RouterState} from "mobx-state-router";

export interface IMobileStore extends IBaseStore<IActivity> {

}


export class MobileStore extends BaseStore<IActivity> implements IMobileStore {


    constructor(rootStore: IRootStore) {
        super(rootStore, '/gridApi/mobile/', activityColumns);
    }

    @action.bound
    async onEnter(fromState: RouterState, toState: RouterState) {
        switch (toState.routeName) {
            case 'mobilelist':
                await this.ds.open();
                break;
        }
    }


    @action.bound
    async onExit(fromState: RouterState, toState: RouterState) {
        switch (fromState.routeName) {
            case 'mobilelist':
                this.ds.close();
                break;
        }
        await Promise.resolve();
    }
}