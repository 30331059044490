import {IField} from "../components/dataset/IField";
import {ILangKeys} from "../language/ILang";
import {enumToArray} from "../components/lib/enumToArray";
import {UsergroupRight} from "./rights/enum-usergrouprights";


export const PPAPModuleRights = [
    UsergroupRight.UR_PPAP_SIDEBAR,
    UsergroupRight.UR_PPAP_TEMPLATE,
    UsergroupRight.UR_PPAP_TEMPLATE_INSERT,
    UsergroupRight.UR_PPAP_TEMPLATE_DELETE,
    UsergroupRight.UR_PPAP_PPAP,
    UsergroupRight.UR_PPAP_PPAP_INSERT,
    UsergroupRight.UR_PPAP_PPAP_DELETE];

export const AuditModuleRights = [
    UsergroupRight.UR_AUDIT_SIDEBAR,
    //
    UsergroupRight.UR_AUDIT_TEMPLATE,
    UsergroupRight.UR_AUDIT_TEMPLATE_INSERT,
    UsergroupRight.UR_AUDIT_TEMPLATE_DELETE,
    //
    UsergroupRight.UR_AUDIT_AUDIT,
    UsergroupRight.UR_AUDIT_AUDIT_INSERT,
    UsergroupRight.UR_AUDIT_AUDIT_DELETE,
];

export const INSPECTIONModuleRights = [
    UsergroupRight.UR_INSPECTION_SIDEBAR,
    UsergroupRight.UR_INSPECTION_PLAN,
    UsergroupRight.UR_INSPECTION_PLAN_INSERT,
    UsergroupRight.UR_INSPECTION_PLAN_DELETE,
    UsergroupRight.UR_INSPECTION_INSPECTION,
    UsergroupRight.UR_INSPECTION_INSPECTION_INSERT,
    UsergroupRight.UR_INSPECTION_INSPECTION_DELETE];


export const URText = (rightno: UsergroupRight): ILangKeys => {
    switch (rightno) {
        case UsergroupRight.UR_HOME:
            return 'HOME';
        case UsergroupRight.UR_CONF_CONFIGURATION:
            return "CONFIGURATION";
        //
        case UsergroupRight.UR_ADMIN_SIDEBAR:
            return "ADMINISTRATION";
        //
        case UsergroupRight.UR_ADMIN_USERGROUP:
            return 'USERGROUPS';
        case UsergroupRight.UR_ADMIN_USERGROUP_INSERT:
            return 'ADD';
        case UsergroupRight.UR_ADMIN_USERGROUP_DELETE:
            return 'DELETE';
        //
        case UsergroupRight.UR_ADMIN_USER:
            return 'USERS';
        case UsergroupRight.UR_ADMIN_USER_INSERT:
            return 'ADD';
        case UsergroupRight.UR_ADMIN_USER_DELETE:
            return 'DELETE';
        //
        case UsergroupRight.UR_BASE_SIDEBAR:
            return 'BASEDATA';
        //
        case UsergroupRight.UR_BASE_PRODUCT:
            return 'PRODUCTS';
        case UsergroupRight.UR_BASE_PRODUCT_INSERT:
            return 'ADD';
        case UsergroupRight.UR_BASE_PRODUCT_DELETE:
            return 'DELETE';
        //
        case UsergroupRight.UR_BASE_CUSTOMER:
            return 'CUSTOMERS';
        case UsergroupRight.UR_BASE_CUSTOMER_INSERT:
            return 'ADD';
        case UsergroupRight.UR_BASE_CUSTOMER_DELETE:
            return 'DELETE';
        //
        case UsergroupRight.UR_BASE_SUPPLIER:
            return 'SUPPLIER';
        case UsergroupRight.UR_BASE_SUPPLIER_INSERT:
            return 'ADD';
        case UsergroupRight.UR_BASE_SUPPLIER_DELETE:
            return 'DELETE';
        //
        case UsergroupRight.UR_PROJECT_SIDEBAR:
            return 'PROJECTMANAGEMENT';
        //
        case UsergroupRight.UR_PROJECT_TEMPLATE:
            return 'PROJECTTEMPLATES';
        case UsergroupRight.UR_PROJECT_TEMPLATE_INSERT:
            return 'ADD';
        case UsergroupRight.UR_PROJECT_TEMPLATE_DELETE:
            return 'DELETE';
        //
        case UsergroupRight.UR_PROJECT_PROJECT:
            return 'PROJECTMANAGEMENT';
        case UsergroupRight.UR_PROJECT_PROJECT_INSERT:
            return 'ADD';
        case UsergroupRight.UR_PROJECT_PROJECT_DELETE:
            return 'DELETE';
        //
        case UsergroupRight.UR_PPAP_SIDEBAR:
            return 'PPAP';
        case UsergroupRight.UR_PPAP_TEMPLATE:
            return 'PPAP_LEVEL';
        case UsergroupRight.UR_PPAP_TEMPLATE_INSERT:
            return 'ADD';
        case UsergroupRight.UR_PPAP_TEMPLATE_DELETE:
            return 'DELETE';
        case UsergroupRight.UR_PPAP_PPAP:
            return 'PPAP_ORDERS';
        case UsergroupRight.UR_PPAP_PPAP_INSERT:
            return 'ADD';
        case UsergroupRight.UR_PPAP_PPAP_DELETE:
            return 'DELETE';
        //
        case UsergroupRight.UR_AUDIT_SIDEBAR:
            return 'AUDIT';
        //
        case UsergroupRight.UR_AUDIT_TEMPLATE:
            return 'AUDIT_TEMPLATES';
        case UsergroupRight.UR_AUDIT_TEMPLATE_INSERT:
            return 'ADD';
        case UsergroupRight.UR_AUDIT_TEMPLATE_DELETE:
            return 'DELETE';
        //
        case UsergroupRight.UR_AUDIT_AUDIT:
            return 'AUDITS'
        case UsergroupRight.UR_AUDIT_AUDIT_INSERT:
            return 'ADD';
        case UsergroupRight.UR_AUDIT_AUDIT_DELETE:
            return 'DELETE';
        //
        case UsergroupRight.UR_TASK_SIDEBAR:
            return 'ACTIONS';
        //
        case UsergroupRight.UR_TASK_TASK:
            return 'ACTIONS'
        case UsergroupRight.UR_TASK_TASK_INSERT:
            return 'ADD';
        case UsergroupRight.UR_TASK_TASK_DELETE:
            return 'DELETE';


        default:
            return "Unknown";
    }
};


export interface IUsergroupRight {
    usergroupno: string;
    rightno: number;
    enabled: boolean;
}

export const usergrouprightColumns: IField<IUsergroupRight>[] = [
    {
        fieldName: 'usergroupno',
        dataType: 'string',
        label: 'USERGROUP_USERGROUPNO',
        primaryKey: true,
        rules: ['pk'],
        hide: 'always',
    },
    {
        fieldName: 'rightno',
        dataType: 'number',
        label: 'USERGROUPRIGHT_RIGHTNO',
        rules: ['pk', 'integer'],
        primaryKey: true,
        input: 'select',
        textFunc: URText,
        options: enumToArray(UsergroupRight)
    },
    {
        fieldName: 'enabled',
        dataType: 'boolean',
        label: 'USERGROUPRIGHT_ENABLED',
    },
];