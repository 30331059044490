import * as React from 'react';
import styled from "styled-components";
import {IBaseView} from "../../base-view";


export const TileHeader = ({children}: IBaseView) =>
    <div className="tile-header">{children}</div>


export const Tile = styled.div`
  width: 100%;
  overflow: hidden;
  color: ${props => props.theme.color};
  background-color: ${props => props.theme.bg};

  border-radius: ${props => props.theme.borderRadius};
  border: ${props => props.theme.border};
  box-shadow: ${props => props.theme.boxShadow};

  .tile-header {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;

    .tile-left {
      flex: 1 1 3rem;
    }

    .tile-middle {
      flex: 1 1 auto;
      display: block;
      text-align: center;
      font-size: 1.25rem;
      font-weight: 500;
    }

    .tile-right {
      flex: 0 0 auto;
      color: ${props => props.theme.color};
      background-color: ${props => props.theme.bg};
    }
  }

  .body {

  }

  .footer {

  }
`;