import React from "react";
import {IDashboardProjectCard} from "../dashboard-project-card";
import {Col, Row} from "reactstrap";
import {AuditStatisticScore} from "../../../audit/order/order/top/audit-statistic-score";
import {DashboardTaskStatus} from "../task/dashboard-task-status";
import {action} from "mobx";
import {inject, observer} from "mobx-react";

@inject('rootStore')
@observer
export class DashboardAudit extends React.Component<IDashboardProjectCard, {}> {

    @action.bound
    gotoAudit(auditno: string) {
        const {rootStore: {routerStore}} = this.props;
        return async () => {
            await routerStore.goTo('audit', {auditno: auditno});
        }
    }

    @action.bound
    gotoTasks(projectno: string, activityno: string) {
        const {rootStore: {routerStore}} = this.props;
        return async () => {
            await routerStore.goTo('projectactivityactiontable', {projectno: projectno, activityno: activityno});
        }
    }

    render() {
        const {projectStatus} = this.props;
        return (
            <>
                <Row>
                    <Col>
                        <div style={{textAlign: 'left', fontSize: '14px', fontWeight: 500}}>Audit</div>
                    </Col>
                </Row>
                <Row>
                    {projectStatus.audits
                        .map((audit, i) => {
                                return (
                                    <React.Fragment key={i}>
                                        <Col md={4} style={{paddingTop: '1rem'}}>
                                            <div style={{display: 'block', height: '100px', width: '100%'}}>
                                                <AuditStatisticScore
                                                    auditScore={audit.score}
                                                    ratingMethod={audit.ratingMethod}
                                                />
                                            </div>
                                            <div className="link"
                                                 onClick={this.gotoAudit(audit.auditno)}>
                                                {audit.activity.description1}
                                            </div>
                                        </Col>
                                        {i % 2 === 1 ?
                                            projectStatus.audits[i].actions.length > 0 ?
                                                <Col md={4} style={{paddingTop: '1rem', paddingLeft: 0}}>
                                                    <div style={{display: 'block', height: '100px', width: '100%'}}>
                                                        <DashboardTaskStatus
                                                            projectno={projectStatus.project.projectno}
                                                            activityno={projectStatus.audits[i].activity.activityno}
                                                            catValues={projectStatus.audits[i]?.actionByStatus}/>
                                                    </div>
                                                    <div className="link"
                                                         onClick={this.gotoTasks(projectStatus.project.projectno, projectStatus.audits[i].activity.activityno)}>{projectStatus.audits[i]?.actions.length}&nbsp;Tasks
                                                    </div>
                                                </Col>
                                                : <Col/>
                                            : null}


                                    </React.Fragment>
                                )
                            }
                        )}
                </Row>
            </>
        )
    }
}