/**
 * Created by windt on 6/3/16.
 */
import {ILang} from './ILang';

export const Lang_de: ILang = {
        '': '',
        HOME: 'Home',
        // Buttons
        OK: 'Ok',
        NOK: 'nOk',
        SAVE: 'Speichern',
        CANCEL: 'Abbrechen',
        YES: 'Ja',
        NO: 'Nein',
        PREDOMINATE: 'Überw.',
        NOT_APPLICABLE: 'Nicht anwendbar',
        N_A: 'n.a.',
        ADD: 'Neu',
        DELETE: 'Löschen',
        EDIT: 'Bearbeiten',
        COPY: 'Kopieren',
        RENAME: 'Umbenennen',
            FILTER: 'Filter',
            REFRESH: 'Refresh',
            BACK: 'Zurück',
            CLOSE: 'Schließen',
            // Diverse
            CREATED_BY: 'Ersteller',
            CREATED_DAT: 'Erstelldatum',
            DATE: 'Datum',
            DETAIL: 'Detail',
            UPLOAD: 'Datei Upload',
            CAMERA: 'Kamera',
            DOWNLOAD: 'Datei Download',
            ALL: '(Alle)',
            CREATED: 'Erstellt',
            RESPONSIBLE: 'Verantwortlich',
            LINK: 'Link',
            PROGRESS: 'Fortschritt',
            EXPORT: 'Export',
            IMPORT: 'Import',
            SETTINGS: 'Einstellungen',
            NEXT: 'Nächster',
        PREVIOUS: 'Vorheriger',
        DOOPEN: 'Öffnen',
        DOCLOSE: 'Schließen',
        HANDOVER: 'Übertragen',
        INFO: 'Information',
        // Language
        LANGUAGE: 'Sprache',
        GERMAN: 'Deutsch',
        ENGLISH: 'Englisch',
        //
        LOGIN: 'Anmelden',
        LOGOUT: 'Abmelden',
        LOGIN_ERROR: 'Fehler beim anmelden.',
        LOGIN_INVALID_CREDENTIALS: 'Ungültige Eingabe. Bitte versuchen Sie es noch einmal.',
        //
        DATASELECT: 'Datenauswahl',
        EVALUATION: 'Auswertung',
        REPORT: 'Report',
        Controlchart_settings: 'Ansicht Regelkarte',
        ControlLimits: 'Eingriffsgrenzen',
        ClassLimits: 'Klassengrenzen',
        Calc_Controllimits: 'Calc EGG',
        Calc_Classlimits: 'Calc Grenzen',
        //div
        Unknown: 'unbekannt',
        IMAGE: 'Anhang',
        IMAGES: 'Anhänge',


        N: 'n',
        M: 'm',
        Ntotal: 'n(Gesamt)',
        Min: 'Min',
        Max: 'Max',
        Range: 'Spannweite',
        Xbar: 'μ',
        Xb_3sigma: '+3σ',
        Xb__3sigma: '-3σ',
        SDEV: 's',


        Calculate: 'Berechnen',
        Capability: 'Fähigkeit',
        Histogram: 'Histogramm',
        ProbabilityNet: 'Wahrscheinlichkeitsnetz',
        StatisticalResult: 'Statistische Kennwerte',
        ControlChart: 'Regelkarte',
        Description1: 'Beschreibung 1',
        Description2: 'Beschreibung 2',
        TabTable: 'Tabelle',
        TabDetail: 'Karteikarte',
        BaseData: 'Stammdaten',
        DELETE_ENTRY: 'Eintrag löschen?',
        REALLY_DELETE_ENTRY: 'Eintrag wirklich löschen?',
        CONFIRMATION: 'Bestätigung',

        // Sidebar Divider
        CONFIGURATION: 'Konfiguration',
        LOGBOOK: 'Logbuch',
        ADMINISTRATION: 'Administration',
        BASEDATA: 'Stammdaten',
        PLANING: 'Prüfplanung',
        ORDER: 'Auftrag',
        STATISTIC: 'Auswertung',


        Sigma_estimated: 'Simga(erw)',
        // Chart Type
        CHARTTYPE_XBS: 'Xq / S',
        CHARTTYPE_XBR: 'Xq / R',
        CHARTTYPE_MXMS: 'Moving X / Moving S',
        CHARTTYPE_XMS: 'X / Moving S',
        CHARTTYPE_MXMR: 'Moving X / Moving R',
        CHARTTYPE_XMR: 'X / Moving R',

        // Distribution Models
        DIST_MODEL: 'Modellverteilung',
        DIST_NORMAL: 'Normal',
        DIST_LOGNORMAL: 'Log-normal',
        DIST_WEIBULL: 'Weibull',
        DIST_FOLDED: 'Betrag 1',
        DIST_RAYLEIGH: 'Betrag 2',
        // Char Type
        CHARTYPE_QUANTITATIVE: 'Messend',
        CHARTYPE_QUALITATIVE: 'Attributiv',
        // Config1
        CONFIG_EMAIL_NOTIFICATION: 'Email Benachrichtigungen',
        CONFIG_EMAIL_NEW_ACTION: 'Neue Massnahme',
        CONFIG_EMAIL_NEW_PPAP: 'Neuer PPAP',
        CONFIG_EMAIL_NEW_PROJECT: 'Neues Projekt',
        CONFIG_EMAIL_NEW_ACTIVITY: 'Neue Aktivität',
        CONFIG_EMAIL_NEW_AUDIT: ' Neues Audit',
        CONFIG_EMAIL_NEW_INSPECTION: 'Neue Inspektion',
        CONFIG_EMAIL_NEW_GEMBA: 'Neuer Gemba',
        CONFIG_PPAP_FAMILY: 'PPAP Family',
        CONFIG_PPAP_FAMILY_PART_FIELD: ' Produkt Feld',
        CONFIG_PPAP_FAMILY_PPAP_INDIVIDUAL: 'PPAP individuell',

        // Customer
        CUSTOMER: 'Kunde',
        CUSTOMERS: 'Kunden',
        CUSTOMER_CUSTOMERNO: 'Kunden Nr',
        CUSTOMER_CUSTOMER1: 'Kunde',
        CUSTOMER_CUSTOMER2: 'Firma',
        CUSTOMER_DESCRIPTION1: 'Beschreibung 1',
        CUSTOMER_DESCRIPTION2: 'Beschreibung 2',
        CUSTOMER_STREET: 'Strasse',
        CUSTOMER_POSTCODE: 'PLZ',
        CUSTOMER_CITY: 'Ort',
        CUSTOMER_COUNTRY: 'Land',
        // CustomerContact
        CUSTOMERCONTACT_PERSON: 'Kontaktperson',
        CUSTOMERCONTACT_PERSONS: 'Kontaktpersonen',
        CUSTOMERCONTACT_CONTACTNO: 'Kontakt Nr',
        CUSTOMERCONTACT_CONTACTNAME: 'Name',
        CUSTOMERCONTACT_POSITION: 'Position',
        CUSTOMERCONTACT_EMAIL: 'Email',
        CUSTOMERCONTACT_PHONE1: 'Telefon1',
        CUSTOMERCONTACT_PHONE2: 'Telefon2',
        CUSTOMERCONTACT_TELEFAX: 'Telefax',
        // Datacollection
        DATACOLLECT: 'Datenerfassung',
        // Orderstatus
        OSTATUS_NEW: 'Neu',
        OSTATUS_PROBE_ARRIVED: 'Probeneingang',
        OSTATUS_PROBE_READY: 'Proben erstellt',
        OSTATUS_IN_TREATMENT: 'In Vorbehandlung',
        OSTATUS_MEASURING_READY: 'Prüfbereit',
        OSTATUS_IN_MEASURING: 'In Prüfung',
        OSTATUS_MEASURING_FINISHED: 'Prüfung beendet',
        OSTATUS_VALIDATED: 'Validiert',
        OSTATUS_DECISION_MADE: 'VE getroffen',
        OSTATUS_UPLOADED: 'Zurück gemeldet',
        // Order
        ORDER_ORDERNO: 'Auftrag Nr',
        ORDER_STATUS: 'Status',
        ORDER_DATACOLLECT: 'Messen',
        ORDER_DECISION: 'Verwendungsentscheid',
        //
        OPROCESS: 'Prozess',
        OPCHAR: 'Merkmal',
        OPSAMPLES: 'Stichproben',
        //
        SAMPLE_SAMPLENO: 'Stpr Nr',
        // Plantyp
        PLANTYP_INCOMING: 'Wareneingang',
        PLANTYP_SPC: 'SPC',
        PLANTYP_OUTGOING: 'Warenausgang',
        PLANTYP_LABORATORY: 'Labor',
        // Planstatus
        PSTATUS_NEW: 'Neu',
        PSTATUS_EDITING: 'in Bearbeitung',
        PSTATUS_PRELIM: 'Vorläufige Freigabe',
        PSTATUS_RELEASED: 'Freigegeben',
        PSTATUS_LOCKED: 'Gesperrt',
        // Plan
        PLAN: 'Prüfplan',
        PLANS: 'Prüfpläne',
        PLAN_PLANNO: 'Plan Nr',
        PLAN_VERSION: 'Version',
        PLAN_STATE: 'Status',
        PLAN_DESCRIPTION1: 'Beschreibung 1',
        PLAN_DESCRIPTION2: 'Beschreibung 2',
        PLAN_PLANTYP: 'Plantyp',
        PLAN_GROUP: 'Plangruppe',
        PLAN_HISTORY: 'Historie',
        PLAN_ORDERS: 'Aufträge',
        PLAN_RELEASEDBY: 'Freigeber',
        PLAN_RELEASEDAT: 'Freigabedatum',
        PLAN_COMMENT: 'Kommentar',
        // PlanHisto
        PLANHISTO: 'Historie',
        //PProcess
        PPROCESS: 'Vorgang',
        PPROCESSES: 'Vorgänge',
        PPROCESS_PROCESSES: 'Vorgänge',
        PPROCESS_PROCESSNO: 'Vorgang Nr',
        PPROCESS_PROCESS: 'Vorgang',
        PPROCESS_N: 'n',
        PPROCESS_MTARGET: 'm(Soll)',
        PPROCESS_MACTUAL: 'm(Ist)',
        //PPChar
        PPCHAR: 'Merkmal',
        PPCHARS: 'Merkmale',
        PPCHAR_CHARNO: 'Merkmal Nr',
        PPCHAR_CHARACTERISTIC: 'Merkmal',
        PPCHAR_CHARID: 'Merkmal Id',
        PPCHAR_TYPE: 'Typ',
        PPCHAR_UNITS: 'Einheit',
        PPCHAR_NOMINAL: 'Sollwert',
        PPCHAR_USL: 'OSG',
        PPCHAR_LSL: 'USG',
        PPCHAR_DECIMALS: 'Nk',
        PPCHAR_UCLX: 'OEGx',
        PPCHAR_LCLX: 'UEGx',
        PPCHAR_UCLS: 'OEGs',
        PPCHAR_LCLS: 'UEGs',
        PPCHAR_UCLR: 'OEGr',
        PPCHAR_LCLR: 'UEGr',
        PPCHAR_DISTMODEL: 'Verteilung',
        PPCHAR_AMM: 'AMM',
        PPCHAR_CLASSLIMIT: 'Klassengrenze',
        PPCHAR_CLASSWIDTH: 'Klassenbreite',
        PPCHAR_TESTEQUIPMENT: 'Prüfmittel',
        PPCHAR_X1: 'X1',
        PPCHAR_X2: 'X2',
        PPCHAR_X3: 'X3',
        PPCHAR_X4: 'X4',
        PPCHAR_X5: 'X5',
        PPCHAR_X6: 'X6',
        PPCHAR_X7: 'X7',
        PPCHAR_X8: 'X8',
        PPCHAR_X9: 'X9',
        PPCHAR_X10: 'X10',
        // Product
        PRODUCT: 'Produkt',
        PRODUCTS: 'Produkte',
        PRODUCT_PRODUCTS: 'Produkte',
        PRODUCT_PRODUCTNO: 'Produkt Nr',
        PRODUCT_DESCRIPTION1: 'Produkt Name',
        PRODUCT_DESCRIPTION2: 'Beschreibung 2',
        PRODUCT_PRODUCTGROUP: 'Produktgruppe',
        PRODUCT_DRAWINGNO: 'Zeichnungs Nr',
        PRODUCT_SPEZIFICATION: 'Technische Spezifikation (TPS/ZPS)',
        PRODUCT_PPAP_FAMILY: 'PPAP Famillie',
        PRODUCT_WEIGHT: 'Gewicht',
        PRODUCT_REVISION: 'Revision',
        PRODUCT_REVISIONDATE: 'Revision date',
        // Statmethod
        STATMETHOD: 'Stat. Methode',
        STATMETHOD_METHOD: 'Methode',
        STATMETHOD_DESCRIPTION1: 'Beschreibung 1',
        STATMETHOD_CHARTTYPE: 'Regelkartentyp (N>1)',
        STATMETHOD_CHARTTYPEN1: 'Regelkartentyp (N=1)',
        STATMETHOD_MAFACTOR: 'Moving average Faktor',
        STATMETHOD_SHOWSPECLIMITS: 'Anzeige Spezifikationsgrenzen',
        STATMETHOD_SHOWRAWVALUES: 'Anzeige Urwerte',
        STATMETHOD_SHOWRCHART: 'Anzeige Regelkarte für s',
        STATMETHOD_SIGMA: 'Sigma Schätzer',
        // Sigma Estimators
        SIGMA_SBC4: 'Sq/c4',
        SIGMA_RBD2: 'Rq/d2',
            SIGMA_SB2: 'Sq2',
            SIGMA_S: 'S',
            // Supplier
            SUPPLIER: 'Lieferant',
            SUPPLIERS: 'Lieferanten',
            SUPPLIER_SUPPLIERNO: 'Lieferanten Nr',
            SUPPLIER_SUPPLIER1: 'Lieferant',
            SUPPLIER_SUPPLIER2: 'Firma',
            SUPPLIER_DESCRIPTION1: 'Beschreibung 1',
            SUPPLIER_DESCRIPTION2: 'Beschreibung 2',
            // SupplierContact
            SUPPLIERCONTACT_PERSON: 'Kontaktperson',
            SUPPLIERCONTACT_PERSONS: 'Kontaktpersonen',
            SUPPLIERCONTACT_CONTACTNO: 'Kontakt Nr',
            SUPPLIERCONTACT_CONTACTNAME: 'Name',
            SUPPLIERCONTACT_POSITION: 'Position',
            SUPPLIERCONTACT_EMAIL: 'Email',
            SUPPLIERCONTACT_PHONE1: 'Telefon1',
            SUPPLIERCONTACT_PHONE2: 'Telefon2',
            SUPPLIERCONTACT_TELEFAX: 'Telefax',
            SUPPLIER_CODE: 'Lieferanten Code',
            // User
            USER: 'Benutzer',
            USERS: 'Benutzer',
            USER_USERNAME: 'Benutzer',
            USER_EMAIL: 'Email',
            USER_FIRSTNAME: 'Vorname',
            USER_LASTNAME: 'Nachname',
            USER_INITIALS: 'Kürzel',
            USER_USERGROUP: 'Benutzergruppe',
            USER_NATIONALITY: 'Nationalität',
            USER_PICTURE: 'Bild',
            USER_PASSWORD: 'Passwort',
            USER_CURRENT_PASSWORD: 'aktuelles Passwort',
            USER_NEW_PASSWORD: 'neues Passwort',
            USER_CONFIRMATION_PASSWORD: 'Passwort wiederholen',
            USER_CHANGE_PASSWORD: 'Passowrt ändern',
            USER_ISACTIVE: 'Aktiv',
            USER_TELEFON1: 'Telefon 1',
            USER_TELEFON2: 'Telefon 2',
            USER_FUNCTION: 'Funktion',
        // Usergroup
        USERGROUP: 'Benutzergruppe',
        USERGROUPS: 'Benutzergruppen',
        USERGROUP_USERGROUPNO: 'Benutzergruppe',
        USERGROUP_DESCRIPTION1: 'Beschreibung 1',
        USERGROUP_DESCRIPTION2: 'Beschreibung 2',
        //UsergroupRight
        USERGROUPRIGHTS: 'Rechte',
        USERGROUPRIGHT_RIGHTNO: 'Recht Nr',
        USERGROUPRIGHT_ENABLED: 'Aktiv',
        //Workflow
        WORKFLOW: 'Ablaufsteuerung',
        WORKFLOWS: 'Ablaufsteuerungen',
        WORKFLOW_WORKFLOW: 'Ablaufsteuerung',
        WORKFLOW_DESCRIPTION1: 'Beschreibung 1',
        WORKFLOW_TYP: 'Typ',
        WORKFLOW_TYP_DATACOLLECT: 'Datenerfassung',
        // Decisioncatalog
        CATALOG: 'Katalog',
        CATALOGS: 'Kataloge',
        CATALOG_ENTRIES: 'Katalogeinträge',
        CATALOG_ENTRY: 'Katalogeintrag',
        DESCCAT_DECISIONCATALOG: 'VE-Katalog',
        DESCAT_CATALOGNO: 'Katalog Nr',
        DESCAT_TYPE: 'Typ',
        DESCAT_DESCRIPTON1: 'Beschreibung',
        DESCAT_DETAILS: 'Katalogeinträge',
        DESCATD_ENTRYNO: 'Eintrag Nr',
        DESCAT_TYPE_DECISION: 'Verwendungsentscheid',
        DESCAT_TYPE_AUDIT: 'Audit',
        DESCAT_TYPE_APQP_PHASE: 'APQP Phasen',
        DESCAT_RATING: 'Wertung',
        //AG-GRID
        GRID_noRowsToShow: 'keine Daten verfügbar',
        AUTOSIZE_COLUMNS: 'Spalten ausrichten',
        // Api status codes
        STATUS_OK: 'OK',
        STATUS_ENTRY_ALREADY_EXISTS: 'Eintrag schon vorhanden.',
        STATUS_PRIMARY_KEY_FIELD_EMPTY: 'Primärschlüssel darf nicht leer sein.',
        STATUS_FIELD_EMPTY: 'Feld darf nicht leer sein.',

        CAPABILITY_REPORT: 'Fähigkeitsstudie',
        CAPABILITY_BARS: 'Fähigkeitsbalken',
        // Error messages
        ERRORMESSAGE: 'Fehlermeldung',
        DELETE_RELEASED_PLAN_NOT_ALLOWED: 'Ein freigegebener Prüfplan kann nicht gelöscht werden.',
        ACTION_NOT_POSSIBLE: 'Aktion nicht möglich.',

        TEST: 'Test',

        AUDIT: 'Audit',
        AUDITS: 'Audits',
        AUDIT_AUDITNO: 'Auditno',
        AUDIT_AUDIT: 'Audit',
        AUDIT_LEADAUDITOR: 'Auditleiter',
        AUDIT_COAUDITOR: 'Co-Auditor',
        AUDIT_STATUS: 'Status',
        AUDIT_TYP: 'Typ',
        AUDIT_STARTDATE: 'Audit Beginn',
        AUDIT_ENDDATE: 'Audit Ende',
        AUDIT_COMMENT: 'Zusammenfassung',
        AUDIT_SUPPLIERNO: 'Lieferantennr',
        AUDIT_PLANT: 'Werk',
        //AUDIT_AUDITORS: 'Auditoren',
        AUDIT_PARTICIPANTS: 'Teilnehmer',

        AUDIT_QUESTIONNO: 'Frage Nr',
        AUDIT_QUESTION: 'Frage',
        AUDIT_PART: 'Teil',
        AUDIT_ELEMENTS: 'Elemente',
        AUDIT_ELEMENT: 'Element',
        AUDIT_SUBELEMENT: 'Subelement',
        AUDIT_REQUIREMENTS: 'Forderungen',
        AUDIT_TARGETPOINTS: 'Ziel',
        AUDIT_ACTUALPOINTS: 'Aktuell',
        AUDIT_NO_COMPLIANCE: '0 - Forderungen nicht erfüllt',
        AUDIT_UNSATISFACTORY_COMPLIANCE: '4 - Forderungen unzureichend erfüllt.',
        AUDIT_PARTIAL_COMPLIANCE: '6 - Forderungen teilweise erfüllt.',
        AUDIT_PREDOMINANT_COMPLIANCE: '8 - Forderungen überwiegend erfüllt.',
        AUDIT_FULL_COMPLIANCE: '10 - Forderungen voll erfüllt',
        AUDIT_UNRATED: 'unbewertet',
        AUDIT_0: '0',
        AUDIT_4: '4',
        AUDIT_6: '6',
        AUDIT_8: '8',
        AUDIT_10: '10',
        AUDIT_YES: 'Ja',
        AUDIT_NO: 'Nein',
        AUDIT_REMARKS: 'Ergebnisse und Anmerkungen',
        AUDIT_EVIDENCE: 'Eingesehene Dokumente',
        AUDIT_EDITEDBY: 'Bearbeited  von',
        AUDIT_EDITEDAT: 'Bearbeited am',
        AUDIT_RATINGTYP: 'Bewertung',
        AUDIT_REASON: 'Anlass',
        AUDIT_MANDATORY: 'Pflichtfrage',
        AUDIT_ANSWER: 'Bemerkung',
        AUDIT_REFERENCE: 'Referenz',
        AUDIT_AREAS: 'Bereiche',
        AUDIT_AREA: 'Bereich',
        AUDIT_SCORE: 'Score',
        SYSTEMAUDIT: 'Systemaudit',
        PROCESSAUDIT: 'Prozeßaudit',
        PRODUCTAUDIT: 'Produktaudit',
        AUDIT_PROJECTNO: 'ProjektNr',
        AUDIT_PROJECTNAME: 'Projectname',
        AUDIT_STATUS_NEW: 'Neu',
        AUDIT_STATUS_PLANNED: 'geplant',
        AUDIT_STATUS_PROCESS: 'In Durchführung',
        AUDIT_STATUS_FINISHED: 'Durchgeführt',
        AUDIT_STATUS_COMPLETED: 'Erledigt',
        AUDIT_FILTER_BY_QUESTION: 'Filter nach Frage',
        AUDIT_QUESTION_EXTRA01: 'Findings',
        AUDIT_QUESTION_EXTRA02: 'Responsible',
        AUDIT_QUESTION_LEVEL01: 'Level 1',
        AUDIT_QUESTION_LEVEL02: 'Level 2',
        AUDIT_QUESTION_LEVEL03: 'Level 3',
        AUDIT_QUESTION_LEVEL04: 'Level 4',
        AUDIT_QUESTION_LEVEL05: 'Level 5',
        AUDIT_QUESTION_UNSELECTED: 'Abgewählt',
        AUDIT_QUESTION_ATTENTIONFLAG: 'Attentionflag',
        AUDIT_QUESTION_CHAPTER: 'Kapitel',
        AUDIT_QUESTION_WEIGHTING: 'Gewichtung',
        //
        AUDIT_TEMPLATES: 'Audit Vorlagen',
        AUDIT_TEMPLATE: 'Audit Vorlage',
        AUDIT_TEMPLATE_ID: 'Vorlage',
        AUDIT_TEMPLATE_PARENTID: 'parentid',
        AUDIT_TEMPLATE_TYP: 'Typ',
        AUDIT_TEMPLATE_RATINGTYP: 'Bewertung',
        AUDIT_TEMPLATE_RATING_VDA_PROCESS: 'VDA Prozess',
        AUDIT_TEMPLATE_RATING_VDA_SYSTEM: 'VDA System',
        AUDIT_TEMPLATE_RATING_MATURITY: 'Reifegrad',
        AUDIT_TEMPLATE_TYP_AREA: 'Bereich',
        AUDIT_TEMPLATE_TYP_ELEMENT: 'Element',
        AUDIT_TEMPLATE_TYP_QUESTION: 'Frage',
        AUDIT_TEMPLATE_TYP_SUBQUESTION: 'Unterfrage',
        AUDIT_TEMPLATE_QUESTIONNO: 'Frage Nr',
        AUDIT_TEMPLATE_QUESTION: 'Frage',
        AUDIT_TEMPLATE_QUESTIONS: 'Fragenkatalog',
        AUDIT_TEMPLATE_CHECKLIST: 'Checkliste',
        AUDIT_TEMPLATE_ELEMENT: 'Element',
        AUDIT_TEMPLATE_REQUIREMENTS: 'Anforderungen',
        AUDIT_TEMPLATE_RATING: 'Bewertung',
        AUDIT_TEMPLATE_RATINGMETHOD: 'Bewertungsschema',
        AUDIT_TEMPLATE_VDA61: 'VDA 6.1',
        AUDIT_TEMPLATE_BINARY: 'Binär',
        AUDIT_TEMPLATE_VDA63: ' VDA 6.3',
        AUDIT_TEMPLATE_VESTAS_SBA: ' Vestas SBA',

        AUDIT_CHECKLIST_NO: 'Nr',
        AUDIT_CHECKLIST_KEYWORD: 'Stichworte',
        AUDIT_CHECKLIST_FINDINGS: 'Findings',
        AUDIT_CHECKLIST_RATING: 'Bewertung',

        AUDIT_RATING_NONE: '',
        AUDIT_RATING_PKT4: '4',
        AUDIT_RATING_PKT6: '6',
        AUDIT_RATING_PKT8: '8',
        AUDIT_RATING_PKT10: '10',
        AUDIT_RATING_NA: 'N/A',
        AUDIT_DEFINED_IN_QM: 'Im System vollständig festgelegt',
        AUDIT_PROVEN_IN_PRACTICE: 'In der Praxis angewendet',

        AUDIT_EVAL_OVERALL: 'Ergebnis',
        AUDIT_EVAL_AREA: 'Bereiche',


        //
        ACTION: 'Massnahme',
        ACTIONS: 'Massnahmen',
        ACTION_NO: 'M-Nr',
        ACTION_TYPE: 'Massnahmentyp',
        ACTION_PROBLEM: 'Problembeschreibung',
        ACTION_ACTION: 'Massnahme',
        ACTION_STATUS: 'Status',
        ACTION_RESPONSIBLE: 'Verantwortlich',
        ACTION_DURATION: 'Dauer (Tage)',
        ACTION_DUEDATE: 'Termin',
        ACTION_CREATEDBY: 'Ersteller',
        ACTION_CREATEDAT: 'Erstell Datum',
        ACTION_COMPLETEDDAT: 'Erledigt am',
        ACTION_KEY1: 'Link',
        ACTION_KEY2: 'Element/Aktivität',
        ACTION_KEY3: '',
        ACTION_KEY4: '',
        ACTION_CONDUCTED: 'Durchgeführt',
        ACTION_KEYWORD: 'Stichwort',
        ACTION_ATTACHEMENTS: 'Anhänge',
        ACTION_COMMENT: 'Kommentar',
        ACTION_OWNER: 'Taskeigner',
        //
        TASK_STATUS_NEW: 'Neu',
        TASK_STATUS_INPROGRESS: 'In Bearbeitung',
        TASK_STATUS_COMPLETED: 'Erledigt',
        //
        PROJECTMANAGEMENT: 'Projektmanagement',
        PROJECT: 'Projekt',
        PROJECTS: 'Projekte',
        PROJECT_PROJECTNO: 'Projekt Nr',
        PROJECT_PROJECT: 'Projekt',
        PROJECT_PROJECTGROUP: 'Projektgruppe',
        PROJECT_SUBGROUP01: 'Projektuntergruppe',
        PROJECT_DOCUMENTS: 'Anhang',
        PROJECT_OWNER: 'Projekteigner',
        PROJECT_COMMENT: 'Kommentar',
        PROJECT_CUSTOMER_RESPONSIBLE: 'Kunden Kontaktperson',
        //PROJECT_SUPPLIER_CONTACT: 'Lieferanten Kontaktperson',
        PROJECT_STATUS: 'Status',
        PROJECT_START: 'Start',
        PROJECT_TERMIN: 'Termin',
        PROJECT_EXTRAFIELD1: 'Extrafeld 1',
        PROJECT_EXTRAFIELD2: 'Extrafeld 2',
        PROJECT_EXTRAFIELD3: 'Extrafeld 3',
        PROJECT_EXTRAFIELD4: 'Extrafeld 4',
        PROJECT_ORIGIN: 'Aktivitäten übernehmen aus:',
        PROJECT_MEMBER: 'Projektmitglieder',
        PROJECTTASK_TYP: 'Typ',
        PROJECTTASKTYP_TASK: 'Aktivität',
        PROJECTTASKTYP_AUDIT: 'Audit',
        PROJECTTASKTYP_PPAP: 'PPAP',
        PROJECTTASKTYP_APQP: 'APQP',
        PROJECTTASKTYP_INSPECTION: 'Inspection',
        PROJECTTASKTYP_GEMBAWALK: 'Gemba Walk',
        PROJECTTASK_TASKNO: 'A-Nr',
        PROJECTTASK_DESCRIPTION1: 'Beschreibung',
        PROJECTTASK_ACTIVITY: 'Aktivität',
        PROJECTTASK_ACTIVITIES: 'Aktivitäten',
        PROJECTTASK_PHASE: 'Phase',
        PROJECTTASK_STARTDATE: 'Start',
        PROJECTTASK_DUEDATE: 'Termin',
        PROJECTTASK_RESPONSIBLE: 'Verantwortlich',
        PROJECTTASK_LINK: 'Link',
        PROJECT_ACTIVITY_ADD: 'Aktivitäten Neu',
        PROJECT_ACTIVITY_DELETE: 'Aktivitäten Löschen',
        PROJECT_ACTIVITY_CREATE_IMPROVEMENT: 'Erzeuge Improvement',
        PROJECTTASK_GANTT: 'Gantt Diagramm',

        PROJECTDOCUMENT_DOCUMENTNO: 'D-Nr',
        PROJECTDOCUMENT_DOCUMENT: 'Dokument',

        PROJECTTEMPLATE: 'Projekt Vorlage',
        PROJECTTEMPLATES: 'Projekt Vorlagen',
        PROJECTTEMPLATE_TEMPLATENO: 'Vorlagen Nr',
        PROJECTTEMPLATE_TEMPLATE: 'Vorlage',


        PPAP: 'Produktqualifikation',
        PPAP_LEVEL: 'Standards',
        PPAP_STANDARD: 'Standard',
        PPAP_REFERENCE: 'Referenz Norm',
        //PPAP_DOCUMENTATION_MATRIX: 'PPAP Dokumentationsmatrix',
        PPAP_RISKLEVEL: 'Risikogruppe',
        PPAP_RISKLEVEL_LOW: 'Niedrig',
        PPAP_RISKLEVEL_MEDIUM: 'Mittel',
        PPAP_RISKLEVEL_HIGH: 'Hoch',
        PPAP_ORDER: 'PPAP',
        PPAP_ORDERS: 'PPAP',
        PPAP_ORDERNO: 'PPAP Nr',
        PPAP_ELEMENTS: 'Elemente',
        PPAP_ELEMENT: 'Element',
        PPAP_ELEMENTNO: 'El-Nr',
        PPAP_ELEMENT_REFERENCE: 'Referenz',
        PPAP_ELEMENT_TEMPLATE: 'Template Datei',
        PPAP_ELEMENT_REQUIREMENTS: 'Forderungen',
        PPAP_STATUS: 'Status',
        PPAP_DOCUMENTATION: 'Dokumentation',
        PPAP_SUBMISSION: 'Vorlage',
        PPAP_SUBMIT: 'Vorlage',
        PPAP_RETAIN: 'Einsicht',
        PPAP_SUBMIT_OR_RETAIN: '*',
        PPAP_MANDATORY: 'Pflicht',
        PPAP_OPTIONAL: 'Optional',
        PPAP_OWNER: 'Bearbeiter',
        PPAP_FILTER_MANDATORY: 'Nur Pflichtelemente',
        PPAP_FILTER_SUBMIT: 'Nur Vorlageelemente',
        PPAP_FILTER_ELEMENT: 'Filter auf Element',
        PPAP_VDA: 'VDA',
        PPAP_AIAG: 'AIAG',
        PPAP_APQP4WIND: 'APQP4Wind',
        PPAP_PHASE: 'Phase',
        PPAP_RESPONSIBLE_SUPPLIER: 'Verantworlich Lieferant',
        PPAP_BLOG: 'Blog',
        PPAP_COMMENTNO: 'Nr',
        PPAP_DOCUMENT: 'Dokument',
        PPAP_UPLOADS: 'Uploads',
        PPAP_EXTRADOCS: 'Anhänge',
        PPAP_SUBMISSION_DATE: 'PSW Vorlage Datum',
        PPAP_REVISION: 'PPAP Rev',
        PPAP_PURCHASENO: 'PPAP AuftragsNr',
        PPAP_ECLREVISION: 'ECL Revision',
        PPAP_ECLDATE: 'ECL Datum',
        PPAP_RESPONSIBLE_CUSTOMER: 'Verantwortlich Kunde',
        PPAP_PPAP4WIND_DOCUMENTATION: 'PPAP4Wind Dokumentation',
        PPAP_GENERAL_INFO: 'Allgemeines',
        PPAP_PRODUCT_INFO: 'Produkt Information',
        PPAP_PPAP_INFO: 'PPAP Information',
        PPAP_CUSTOMER_INFO: 'Kunden Information',
        PPAP_SUPPLIER_INFO: 'Lieferanten Information',
        PPAP_SHEET_INFO: 'Element spezifische Information',
        PPAP_TEMPLATE: 'Formular',
        PPAP_PRODUCT_QUALITY_PLAN: 'Product Quality Plan',

        STATUS_PLANNED: 'geplant',
        STATUS_INPROGRESS: 'In Bearbeitung',
        STATUS_COMPLETED: 'Erledigt',
        STATUS_NOT_APPROVED: 'Nicht Akzeptiert',
        STATUS_COND_APPROVED: 'Bedingt Akzeptiert',
        STATUS_APPROVED: 'Akzeptiert',
        //
        PPAPDOCUMENT_DOCUMENTNO: 'Nr',
        PPAPDOCUMENT_DOCUMENT: 'Dokument',
        //
        QUALITY_PLANING: 'Qualitätsplanung',
        APQP: 'APQP',
        APQP_STANDARDS: 'APQP Standards',
        APQP_STANDARD: 'APQP Standard',
        APQP_MATRIX: 'APQP Matrix',
        APQP_ELEMENT: 'APQP Element',
        APQP_ELEMENTS: 'APQP Elemente',


        ERROR_PRIMARY_KEY_CANNOT_BE_EMPTY: 'Pflichtfeld',
        ERROR_MANDATORY_FIELD_CANNOT_BE_EMPTY: 'Pflichtfeld',
        ERROR_INVALID_NUMERIC_VALUE: 'Numerischer Wert erwartet',
        ERROR_INVALID_INTEGER_VALUE: 'Ganzzahl erwartet',
        ERROR_VALUE_IS_NOT_EQUAL: 'Wert ist nicht gleich',

        INSPECTIONS: 'Inspections',
        INSPECTION: ' Inspection',
        INSPECTION_PLANS: 'Inspection Plans',
        INSPECTION_PLAN: 'Inspection Plan',

        INSPECTION_PLAN_PLANNO: 'Planno',
        INSPECTION_PLAN_QUESTIONNO: '',
        INSPECTION_PLAN_QUESTIONS: 'Questionaire',
        INSPECTION_PLAN_AREA: 'Area',
        INSPECTION_PLAN_ELEMENT: 'Element',
        INSPECTION_PLAN_QUESTION: 'Question',
        INSPECTION_PLAN_MANDATORY: 'Mandatory',
        INSPECTION_INSPECTIONNO: 'Inspectionno',
        INSPECTION_INSPECTION: 'Inspection',
        INSPECTION_INSPECTOR: 'Inspector',

        GEMBAS: 'Gembas',
        GEMBA: 'Gemba',
        GEMBA_GEMBANO: 'GembaNr',
        GEMBA_GEMBA: 'Gemba',
        GEMBA_FINDINGS: 'Beobachtungen',
        GEMBA_FINDINGNO: 'Nr',
        GEMBA_FINDING: 'Beobachtung',
        GEMBA_AREA: 'Ort',
        GEMBA_ELEMENT: 'Gemba Element',
            GEMBA_TEMPLATES: 'Gemba Vorlagen',
            GEMBA_TEMPLATE: 'Gemba Vorlage',
            GEMBA_TEMPLATE_TEMPLATE: 'Vorlage',
            GEMBA_TEMPLATE_DESCRIPTION: 'Beschreibung',
            GEMBA_TEMPLATE_AREA_CAT: 'Area Katalog',
            GEMBA_TEMPLATE_ELEMENT_CAT: 'Element Katalog',
            GEMBA_KEYWORD: 'Stichwort',
            GEMBA_POSNEG: 'Pos/Neg',
            OFFLINE_ACTIVITIES: 'Offline Aktivitäten',

            MOBILE: 'Mobil',
            MOBILE_ACTIVITIES: 'Mobil Aktivitäten',


            FILTER_ALL: 'Filter All'

    }
;
