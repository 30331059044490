import {IRootStore} from "../routes/root-store";
import {RouterState} from "mobx-state-router";
import {action, observable, runInAction} from "mobx";
import axios from 'axios';
import {authorizer} from "../components/dataset/authorizer";
import {IProject, projectColumns} from "../models/Project";
import {IProjectActivity} from "../models/ProjectActivity";
import {IAction} from "../models/Action";
import {ICatValue} from "../components/graphic/bar-graph";
import * as R from 'ramda'
import {BaseStore, IBaseStore} from "./base-store";
import {enumToArray} from "../components/lib/enumToArray";
import {Status, STATUS_ALL} from "../models/Status";
import {RatingMethod} from "../models/AuditTemplate";
import {IPpapOrderElement} from "../models/PpapOrderElement";
import {IGantt} from "../views/dashboard/project/gantt/dashboard-gantt-chart";


interface IAuditScore {
    auditno: string;
    score: number;
    ratingMethod: RatingMethod,
    activity: IProjectActivity;
    actions: IAction[];
    actionByStatus: ICatValue[];
}

interface IPpapStatus {
    ppapno: string;
    productno: string;
    duedate: string;
    approved: number;
    value: number;
    status: Status;
    elements: IPpapOrderElement[];
}

interface IProjectwithLogo extends IProject {
    supplierlogo: string;
    suppliericon: string;
}

export interface IGembaDashboard {
    gembano: string;
    gemba: string;
    activity: IProjectActivity;
    findings: number;
    positive: number;
    negative: number;
    actions: IAction[];
    actionByStatus: ICatValue[];

}

export interface IProjectStatus {
    project: IProjectwithLogo;
    audits: IAuditScore[];
    ppapstati: IPpapStatus[];
    gemba: IGembaDashboard[];
    gantt: IGantt;
}

export interface IDashboardStore extends IBaseStore<IProject> {
    rootStore: IRootStore;
    onEnter: (fromState: RouterState, toState: RouterState) => Promise<void>;
    onExit: (fromState: RouterState, toState: RouterState) => Promise<void>;
    projectStati: IProjectStatus[];
}


export class DashboardStore extends BaseStore<IProject> {


    @observable
    projectStati: IProjectStatus[];

    constructor(rootStore: IRootStore) {
        super(rootStore, '/gridApi/project/', projectColumns);

        // Für den Statusfilter STATUS_ALL hinzufügen
        let status = this.cdsFilter.columns
            .find(column => column.fieldName === 'status');
        status.options = enumToArray(Status);
        status.options.unshift(STATUS_ALL);
        status.defaultValue = STATUS_ALL;


    }


    @action.bound
    calcActionBars(actions: IAction[]): ICatValue[] {
        let x = actions.reduce((values: any, action) => {
                values[action.status] = values[action.status] ? values[action.status] + 1 : 1;
                return values;
            }, {
                100: 0,
                200: 0,
                300: 0,
                350: 0,
                390: 0,
                400: 0
            }
        )

        return R.keys(x).map((key: any) => {
            return {
                'category': key,
                'value': x[key]
            }


        });
    }


    @action.bound
    async onEnter() {
        await this.initializeAsync();
        await this.loadFilter('dashboard');
        await this.open();
    }

    @action.bound
    async open() {
        const dash: any = await axios.get('/gridApi/dashboard', authorizer({}));
        //console.log(toJS(dash))
        runInAction(() => {
            this.projectStati = dash.data.data;
            this.projectStati = this.projectStati
                .filter(projectStatus => {
                        return (!this.cdsFilter.actual.projectgroup || projectStatus.project.projectgroup === this.cdsFilter.actual.projectgroup) &&
                            (!this.cdsFilter.actual.subgroup01 || projectStatus.project.subgroup01 === this.cdsFilter.actual.subgroup01) &&
                            (!this.cdsFilter.actual.status || projectStatus.project.status === this.cdsFilter.actual.status) &&
                            (!this.cdsFilter.actual.owner || projectStatus.project.owner === this.cdsFilter.actual.owner) &&
                            (!this.cdsFilter.actual.customerno || projectStatus.project.customerno === this.cdsFilter.actual.customerno) &&
                            (!this.cdsFilter.actual.supplierno || projectStatus.project.supplierno === this.cdsFilter.actual.supplierno);
                    }
                );
            this.projectStati
                .forEach(projectStatus => {
                    projectStatus.audits.forEach(audit => {
                        audit.actionByStatus = this.calcActionBars(audit.actions);
                    })
                    projectStatus.gemba.forEach(gemba => {
                        gemba.actionByStatus = this.calcActionBars(gemba.actions);
                    })
                });
        });
    }


    @action.bound
    async onExit() {
        await this.saveFilter('dashboard');
        await Promise.resolve();
    }


}