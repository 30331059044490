import * as React from 'react';
import {inject, observer} from "mobx-react";
import {IBaseView} from "../../_base/base-view";
import {action, observable} from "mobx";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import styled from "styled-components";


@inject('rootStore')
@observer
export class _HeaderUser extends React.Component<IBaseView, {}> {

    @observable
    isopen = false;

    @action.bound
    toggle() {
        this.isopen = !this.isopen;
    }

    @action.bound
    goToLogout() {
        const {rootStore: {authStore}} = this.props;
        authStore.doLogout();
    };

    @action.bound
    goToSettings() {
        const {rootStore: {routerStore}} = this.props;
        routerStore.goTo('settings')
    }

    render() {

        const {rootStore: {authStore: {username}, langStore: {lang, setLanguage}}, className} = this.props;
        return (
            <div className={className}>
                <Dropdown style={{display: 'inline-block'}} isOpen={this.isopen}
                          toggle={this.toggle} direction="down">
                    <DropdownToggle tag="span" className="headeruser">
                        {username}&nbsp;&nbsp;<FontAwesomeIcon icon="user"/>
                    </DropdownToggle>
                    <DropdownMenu style={{fontSize: 14}} right={true}>
                        <DropdownItem onClick={() => {
                            setLanguage('Lang_de')
                        }}>{lang.GERMAN}</DropdownItem>
                        <DropdownItem onClick={() => {
                            setLanguage('Lang_en')
                        }}>{lang.ENGLISH}</DropdownItem>
                        <DropdownItem divider/>
                        <DropdownItem onClick={this.goToSettings}>
                            {lang.SETTINGS}
                        </DropdownItem>
                        <DropdownItem divider/>
                        <DropdownItem onClick={this.goToLogout}>{lang.LOGOUT}</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </div>
        )
    }
}

export const HeaderUser = styled(_HeaderUser)`

  .headeruser {
    padding-top: 0.5rem;
    background-color: ${props => props.theme.header.bg};
    border: 0;
    color: ${props => props.theme.header.color};
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
  }`;


